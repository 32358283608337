import { app, authentication } from "@microsoft/teams-js";

import { getCookie } from "./utils";

async function getClientSideToken() {
    app.initialize();
    try {
        return await authentication.getAuthToken();
    } catch (error) {
        throw new Error("Error getting token: " + error);
    }
}

export default function teamsAuthService() {
    return {
        loading: false,
        async login(ignoreError = false, success = false, error = false, token = null) {
            this.loading = true;
            if (success || error) {
                this.loading = false;
                return;
            }
            // On redirect to the EU, we should already have a token. This is needed because the Teams auth SDK can
            // only be configured to one domain so this will fail if we try it after the EU redirect.
            if (!token) {
                token = await getClientSideToken();
            }

            fetch("/microsoft-teams/login/", {
                method: "POST",
                body: JSON.stringify({ token }),
                headers: {
                    "Content-type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.redirect) {
                        if (ignoreError && !data.success) {
                            this.loading = false;
                            return;
                        }
                        let redirectUrl = data.redirect;
                        if (data.token) {
                            const separator = redirectUrl.includes("?") ? "&" : "?";
                            redirectUrl += `${separator}token=${encodeURIComponent(data.token)}`;
                        }
                        window.location.replace(redirectUrl);
                    } else {
                        window.location.href = window.location.href + "?error=Unknown error";
                    }
                });
        },
        async verifyOTP(token = null) {
            this.loading = true;
            if (!token) {
                token = await getClientSideToken();
            }

            fetch("/microsoft-teams/verify-otp/", {
                method: "POST",
                body: JSON.stringify({ token, otp: document.getElementById("otp").value }),
                headers: {
                    "Content-type": "application/json",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.redirect) {
                        window.location.replace(data.redirect);
                    } else {
                        window.location.href = window.location.href + "?error=Unknown error";
                    }
                    this.loading = false;
                });
        },
    };
}
